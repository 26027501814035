import { DomManager, Store } from "@/scripts/core";
import components from "@/scripts/components";
import modules from "@/scripts/modules";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

/* Lazysizes plugin to lazy load images */
// DOCS: https://github.com/aFarkas/lazysizes
import "lazysizes";

// DOCS: https://swiperjs.com/
// imported in main.css

/**
 * Set up the initial values and keys for the store. This should be done first
 * thing before mounting and creating the components.
 */
Store.setState({});

// EXTEND:Sprig + Vanilla JS
function init() {
	DomManager.setComponents(components);
	DomManager.setModules(modules);
}

DomManager.define({
	/**
	 * Triggers first and immediately, does not wait for a state. Only
	 * initialize DOM based classes that do not require calculations with
	 * dimensions, because the dimensions of certain elements might not be set
	 * immediately.
	 */
	created() {},

	/**
	 * Triggers on 'interactive' state, if 'interactive' never fires, it is
	 * triggered on 'complete' state. Dimensions are known and set
	 */
	mounted() {
		// Register plugins
		gsap.registerPlugin(ScrollTrigger);

		document.body.classList.remove("preload");

		init();
	},

	// Triggers last and on 'complete' state
	completed() {},
});

// EXTEND:Sprig + Vanilla JS
document.addEventListener("sprig-refresh", () => {
	DomManager.destroy();
	init();
});

document.addEventListener("DOMContentLoaded", function() {
 let lazyVideos = [...document.querySelectorAll("video.lazy")]

	if ("IntersectionObserver" in window) {
		let lazyVideoObserver = new IntersectionObserver(function(entries) {
			entries.forEach(function(video) {
				if (video.isIntersecting) {
					for (let source in video.target.children) {
						let videoSource = video.target.children[source];
						if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
							videoSource.src = videoSource.dataset.src;
						}
					}

					video.target.load();
					video.target.classList.remove("lazy");
					lazyVideoObserver.unobserve(video.target);
				}
			});
		});

		lazyVideos.forEach(function(lazyVideo) {
			lazyVideoObserver.observe(lazyVideo);
		});
	}
});

// Prevent showing videos if Cookiebot is blocked
// eslint-disable-next-line no-undef
/*
if (typeof Cookiebot.consent === "undefined") {
	const blockedContent = document.querySelectorAll(".cookieconsent-optin-marketing");

	blockedContent.forEach((item) => {
		item.style.display = "none";
	});
}
*/
