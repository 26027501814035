import { Element } from "@/scripts/extends";
import keyboard from "@/scripts/singletons/Keyboard";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import {EVENTS} from "@/scripts/constants";
import { EventEmitter } from "@/scripts/core";

export default class Modal extends Element {
	mounted() {
		this.isShown = false;
		this.id = this.el.id;
		this.openButtons = document.querySelectorAll(`.js-modal-trigger[data-modal=${this.id}]`);
		this.closeButton = this.el.querySelector(".c-modal__close");
		this.backdrop = this.el.querySelector(".c-modal__backdrop");
		this.video = this.el.querySelector(".c-video");
		this.initKeyboard();
	}

	events() {
		this.openButtons.forEach((item) => {
			item.addEventListener("click", () => this.open());
		});
		this.closeButton.addEventListener("click", () => this.close());
		this.backdrop.addEventListener("click", () => this.close());
	}

	open() {
		EventEmitter.emit(EVENTS.MODAL_OPEN, this.video);
		this.el.classList.remove("hidden");
		this.el.classList.add("flex");
		disableBodyScroll(this.el);
		document.documentElement.style.overflow = "hidden";
		this.isShown = true;
	}

	close() {
		EventEmitter.emit(EVENTS.MODAL_CLOSE, this.video);
		this.el.classList.add("hidden");
		enableBodyScroll(this.el);
		document.documentElement.style.overflow = "auto";
		this.isShown = false;
	}

	initKeyboard() {
		keyboard.listenOnKeyDown((e) => this.onKeydown(e));
	}

	onKeydown(e) {
		if (!this.isShown) {
			return;
		}

		this.handleEsc(e);
	}

	handleEsc(e) {
		const isEsc = 27;

		if (e.keyCode === isEsc) {
			this.close();
			e.preventDefault();
		}
	}
}
