/**
 * In the /scripts/singletons/DomManager file, when using the setComponents
 * method, all components placed in export default are looped and initialized
 * automatically.
 */
import Button from "@/scripts/components/Button";
import CursorHover from "@/scripts/components/CursorHover";
import Modal from "@/scripts/components/Modal";
import Parallax from "@/scripts/components/Parallax";
import Slider from "@/scripts/components/Slider";
import Video from "@/scripts/components/Video";

/**
 * If you want to pass separate parameters or want to initialize a component
 * manually, you have to:
 * - Export it by itself =>
 *   export { default as Button } from "@/scripts/components/Button";
 * - Import it in the main.js file =>
 *   import { Button } from "@/scripts/components";
 * - Use the DomManager to register it:
 *   DomManager.registerComponent(".js-button", Button);
 */
// export { default as Button } from "@/scripts/components/Button";

export default {
	Button,
	CursorHover,
	Modal,
	Parallax,
	Slider,
	Video,
};
