import { Element } from "@/scripts/extends";
import gsap from "gsap";

export default class Parallax extends Element {
	mounted() {
		const blocks = gsap.utils.toArray(".c-showcase");

		setTimeout(() => {
			this.initGsapAnimation(blocks);
		}, 100);
	}

	initGsapAnimation(blocks){
		blocks.forEach((block, index) => {
			if (index < blocks.length - 1) {
				gsap.to(block, {
					scrollTrigger: {
						trigger: block,
						start: "top top",
						end: "bottom top",
						scrub: true,
						invalidateOnRefresh: true,
						pin: true,
						pinSpacing: false,
					},
					ease: "none",
				});
			}

			let content = block.querySelector(".c-showcase__content");

			gsap.to(content, {
				yPercent: -30,
				ease: "none",
				scrollTrigger: {
					trigger: block,
					start: "top top",
					scrub: true,
				},
			});
		});
	}
}
