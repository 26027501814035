import { Element } from "@/scripts/extends";
import { EVENTS } from "@/scripts/constants";
import { EventEmitter } from "@/scripts/core";
class Html extends Element {
    events() {
		document.addEventListener("scroll", this.onScroll);
	}

	onScroll(e) {
		EventEmitter.emit(EVENTS.DOC_SCROLL, e);
	}

	disableScroll() {
		this.el.style.overflow = "hidden";
	}

	enableScroll() {
		this.el.style.overflow = "auto";
	}

	destroy() {
		document.removeEventListener("scroll", this.onScroll);
	}
}

const html = new Html(document.documentElement);

export default html;
