import { Element } from "@/scripts/extends";
import { Mouse } from "@/scripts/singletons";
import { Window } from "@/scripts/singletons";
import { EVENTS } from "@/scripts/constants";
import { EventEmitter } from "@/scripts/core";

export default class CursorHover extends Element {
	mounted() {
		this.cursor = this.el.querySelector(".c-cursor");
		this.cases = this.el.querySelectorAll(".c-showcase");

		this.left = this.getCoordinates("left");
		this.top = this.getCoordinates("top");
	}

	events() {
		this.cases.forEach((el) => {
			el.addEventListener("mouseenter", (event) => {
				setTimeout(() => {
					this.handleMouseEnter(event);
				}, 40);
			});
			
			el.addEventListener("mouseleave", () => this.handleMouseLeave());
		});

		EventEmitter.on(EVENTS.DOC_SCROLL, () => this.handleScroll());
		Window.listenOnResize(() => this.handleResize());
		Mouse.listenOnMouseMove(() => this.handleMove());
	}

	handleScroll() {
		this.updateCoordinates();
		this.updateCursorPosition(Mouse.clientX, Mouse.clientY);
	}

	handleResize() {
		this.updateCoordinates();
		this.updateCursorPosition(Mouse.pageX, Mouse.pageY - window.scrollY);
	}

	handleMove() {
		this.updateCoordinates();
		this.updateCursorPosition(Mouse.pageX, Mouse.pageY - window.scrollY);
	}

	handleMouseEnter(event) {
		this.updateCursorStyles(1);
		const cursorBgColor = event.target.getAttribute('data-cursor-bg');
		const cursorTextColor = event.target.getAttribute('data-cursor-text');
		
		this.cursor.style.backgroundColor = `${cursorBgColor}`;
		this.cursor.style.color = `${cursorTextColor}`;
	}

	handleMouseLeave() {
		this.updateCursorStyles(0);
	}

	updateCursorStyles(scale) {
		this.cursor.style.transform = `translate(-50%, -50%) scale(${scale})`;
		this.cursor.style.opacity = scale;
	}

	getCoordinates(type) {
		const { [type]: coordinate } = this.el.getBoundingClientRect();
		return coordinate;
	}

	updateCoordinates() {
		this.top = this.getCoordinates("top");
		this.left = this.getCoordinates("left");
	}

	updateCursorPosition(x, y) {
		this.cursor.style.left = x - this.left + "px";
		this.cursor.style.top = y - this.top + "px";
	}

	destroy() {
		this.el.removeEventListener("mouseenter", (event) => this.handleMouseEnter(event));
		this.el.removeEventListener("mouseleave", () => this.handleMouseLeave());
	}
}