import { Element } from "@/scripts/extends";
import { EVENTS } from "@/scripts/constants";
import { EventEmitter } from "@/scripts/core";

class Window extends Element {
	mounted() {
		this.width = this.getWidth();
	}

	events() {
		window.addEventListener("resize", () => this.resize());
	}

	resize() {
		this.width = this.getWidth();
		EventEmitter.emit(EVENTS.RESIZE, this.width);
	}

	listenOnResize(callback) {
		EventEmitter.on(EVENTS.RESIZE, callback);
	}

	getWidth() {
		return window.innerWidth;
	}

	destroy() {
		window.removeEventListener("resize", this.resize);
	}
}

const windowEl = new Window(window);

export default windowEl;
